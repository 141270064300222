<template>
  <v-dialog v-model="bulkApproveDialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class=" headline" :hidden="price_plan">{{ $t("Fee Plan") }}</span>
        <span class=" headline" :hidden="confirmation_message">
          {{ $t("Confirmation Message") }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" class="mt-5">
            <v-row :hidden="price_plan">
              <v-col cols="12" sm="12" md="12">
                <label>
                  {{ $t("Fee Plan") }}
                </label>
                <v-select
                  :items="plans"
                  item-text="title"
                  item-value="id"
                  v-model="pricePlan.price_plan_id"
                  :rules="[validationRules.required]"
                  solo
                  @change="onSelect()"
                >
                </v-select>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
                v-if="price_paln_id != pricePlan.price_plan_id"
              >
                <label>
                  {{ $t("Fee Plan Change Reason") }}
                </label>
                <v-select
                  :items="[
                    { title: 'Please Select Reason', value: '' },
                    {
                      title: 'Exception from Administration',
                      value: 'Exception from Administration'
                    },
                    {
                      title: 'The son or daughter of an employee',
                      value: 'The son or daughter of an employee'
                    },
                    { title: 'Others', value: 'Other' }
                  ]"
                  item-text="title"
                  item-value="value"
                  v-model="pricePlan.price_plan_reason"
                  solo
                  :rules="[validationRules.required]"
                >
                </v-select>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                v-if="pricePlan.price_plan_reason == 'Other'"
              >
                <label>{{ $t("Please enter reason") }}</label>
                <v-textarea
                  v-model="pricePlan.price_plan_reason_other"
                  solo
                  :rules="[validationRules.required]"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row :hidden="confirmation_message">
              <v-col cols="12" sm="12" md="12">
                <p>
                  {{ $t("The selected fee plan will be assigned to these applicants. Are you sure you want to continue?") }}
                </p>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="space">
        <v-spacer></v-spacer>

        <v-btn
          :hidden="confirmation_message"
          class="modal-btn-save previous"
          @click="GoToPricePlan"
        >
          {{ $t("Previous") }}
        </v-btn>

        <v-btn class="modal-btn-cancel" @click="close">
          {{ $t("Close") }}
        </v-btn>

        <v-btn :hidden="price_plan" class="modal-btn-save" @click="GoToConfirm">
          {{ $t("Finish") }}
        </v-btn>
        <v-btn
          :hidden="confirmation_message"
          class="modal-btn-save"
          @click="submitPricePlanData"
          :disabled="disabledConfirmbtn"
          :loading="disabledConfirmbtn"
        >
          {{ $t("Confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  name: "validateApplicant",
  props: ["bulkApproveDialog", "ids"],
  data() {
    return {
      valid: false,
      price_paln_id: "",
      loading: false,
      modal1: "",
      approve_excuses: false,

      price_plan: false,
      confirmation_message: true,
      disabledSubmitbtn: false,
      disabledConfirmbtn: false,
      pricePlan: {
        price_plan_id: "",
        price_plan_reason: "",
        price_plan_reason_other: "",
        status: "approved",
        ids: []
      },
      plans: []
    };
  },
  mixins: [validationMixin],
  async mounted() {
    await axios
      .get(this.getApiUrl + "/getDefaultFeePlan", {
        headers: {
          Authorization: "Bearer " + localStorage.token
          //the token is a variable which holds the token
        }
      })
      .then(response => {
        if (response.data.status.error == false) {
          this.price_paln_id = response.data.data.id;
        }
      });

    this.pricePlan.price_plan_id = this.price_paln_id;

    axios
      .get(this.getApiUrl + "/getfeesPlans", {
        headers: {
          Authorization: "Bearer " + localStorage.token
          //the token is a variable which holds the token
        }
      })
      .then(response => {
        if (response.data.status.error == false) {
          this.plans = response.data.data;
        }
      })
      .catch(err => {
        alert(err);
      });
  },
  methods: {
    GoToConfirm() {
      if (this.valid == false) {
        this.validate();
      } else {
        this.price_plan = true;
        this.confirmation_message = false;
      }
    },
    GoToPricePlan() {
      this.price_plan = false;
      this.confirmation_message = true;
    },
    close() {
      this.bulkApproveDialog = false;
      this.$emit("childToParentBulkApprove", false);
    },
    submitPricePlanData() {
      if (this.valid == false) {
        this.validate();
      } else {
        this.disabledConfirmbtn = true;

        //console.log(response.data.status.message);
        this.pricePlan.ids = this.ids;
        axios
          .post(
            this.getApiUrl + "/applicant/bulkApproveApplicants",
            this.pricePlan,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token
                //the token is a variable which holds the token
              }
            }
          )
          .then(response => {
            // //console.log(response);

            if (response.data.status.error == false) {
              this.disabledConfirmbtn = false;

              location.reload();
            }
          })
          .catch(err => {
            alert(err);
          });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
</style>
