var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.bulkApproveDialog),callback:function ($$v) {_vm.bulkApproveDialog=$$v},expression:"bulkApproveDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline",attrs:{"hidden":_vm.price_plan}},[_vm._v(_vm._s(_vm.$t("Fee Plan")))]),_c('span',{staticClass:"headline",attrs:{"hidden":_vm.confirmation_message}},[_vm._v(" "+_vm._s(_vm.$t("Confirmation Message"))+" ")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",staticClass:"mt-5",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"hidden":_vm.price_plan}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t("Fee Plan"))+" ")]),_c('v-select',{attrs:{"items":_vm.plans,"item-text":"title","item-value":"id","rules":[_vm.validationRules.required],"solo":""},on:{"change":function($event){return _vm.onSelect()}},model:{value:(_vm.pricePlan.price_plan_id),callback:function ($$v) {_vm.$set(_vm.pricePlan, "price_plan_id", $$v)},expression:"pricePlan.price_plan_id"}})],1),(_vm.price_paln_id != _vm.pricePlan.price_plan_id)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t("Fee Plan Change Reason"))+" ")]),_c('v-select',{attrs:{"items":[
                  { title: 'Please Select Reason', value: '' },
                  {
                    title: 'Exception from Administration',
                    value: 'Exception from Administration'
                  },
                  {
                    title: 'The son or daughter of an employee',
                    value: 'The son or daughter of an employee'
                  },
                  { title: 'Others', value: 'Other' }
                ],"item-text":"title","item-value":"value","solo":"","rules":[_vm.validationRules.required]},model:{value:(_vm.pricePlan.price_plan_reason),callback:function ($$v) {_vm.$set(_vm.pricePlan, "price_plan_reason", $$v)},expression:"pricePlan.price_plan_reason"}})],1):_vm._e(),(_vm.pricePlan.price_plan_reason == 'Other')?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Please enter reason")))]),_c('v-textarea',{attrs:{"solo":"","rules":[_vm.validationRules.required]},model:{value:(_vm.pricePlan.price_plan_reason_other),callback:function ($$v) {_vm.$set(_vm.pricePlan, "price_plan_reason_other", $$v)},expression:"pricePlan.price_plan_reason_other"}})],1):_vm._e()],1),_c('v-row',{attrs:{"hidden":_vm.confirmation_message}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("The selected fee plan will be assigned to these applicants. Are you sure you want to continue?"))+" ")])])],1)],1)],1)],1),_c('v-card-actions',{staticClass:"space"},[_c('v-spacer'),_c('v-btn',{staticClass:"modal-btn-save previous",attrs:{"hidden":_vm.confirmation_message},on:{"click":_vm.GoToPricePlan}},[_vm._v(" "+_vm._s(_vm.$t("Previous"))+" ")]),_c('v-btn',{staticClass:"modal-btn-cancel",on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("Close"))+" ")]),_c('v-btn',{staticClass:"modal-btn-save",attrs:{"hidden":_vm.price_plan},on:{"click":_vm.GoToConfirm}},[_vm._v(" "+_vm._s(_vm.$t("Finish"))+" ")]),_c('v-btn',{staticClass:"modal-btn-save",attrs:{"hidden":_vm.confirmation_message,"disabled":_vm.disabledConfirmbtn,"loading":_vm.disabledConfirmbtn},on:{"click":_vm.submitPricePlanData}},[_vm._v(" "+_vm._s(_vm.$t("Confirm"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }